
import config from '../config/config'
import { canUseDOM } from 'exenv'
const buildtimestamp = require('../buildtimestamp.json')

// On client-side 'maxXyZToLog' applies to one tab so we limit errors heavily.
// On server-side 'maxXyZToLog' applies to lifetime of the process.
let maxExceptionsToLog = canUseDOM  ? 5 : 200
let maxWarningsToLog = canUseDOM ? 5 : 200
let sentry = null


/**
 * Init sentry. Sentry implementation must be provided as a parameter
 * because client-side and server-side have different implementations.
 * @param {import('@sentry/react') | import('@sentry/node') | import('@sentry/browser')} sentryImpl
 */
export function initSentry(sentryImpl) {
  if (config.sentry.sampleRate == null || sentry != null) {
    return
  }

  // Disable SSR sentry for now so not to consume our event quota
  if (!canUseDOM) {
    return
  }
  // Sentry sampleRate applies per event. We turn it to per user (or per
  // browser tab to be more accurate) instead. Most users will not load
  // sentry at all, while some will submit all errors and warnings.
  if (canUseDOM && config.sentry.sampleRate < Math.random()) {
    return
  }

  sentry = sentryImpl
  const sentryConfig = Object.assign({}, config.sentry, {sampleRate: 1.0})
  if (canUseDOM) {
    sentryConfig.environment = window?.location?.hostname ?? 'unknown-client'
  } else {
    sentryConfig.environment = process?.env?.HOSTNAME ?? 'unknown-server'
  }
  sentryConfig.release = buildtimestamp
  sentryConfig.allowUrls = [
    'ampparit.com/',
    'webpack-internal:',
  ]
  sentryConfig.denyUrls = [
    'permutive.app/',
    'adnxs.com/',
  ]
  sentryConfig.beforeSend = beforeSend
  sentry.init(sentryConfig)
}


export function logException(error, message) {
  if (error && sentry && maxExceptionsToLog-- > 0) {
    sentry.withScope(scope => {
      if (message) {
        scope.setTag('message', message)
      }
      if (error.name === 'AxiosError') {
        scope.setTag('axios.url', error.config?.url ?? '')
        scope.setTag('axios.code', error.code ?? '-')
        scope.setTag('axios.status', error.response?.status ?? '-')
        scope.setTag('axios.method', error.config?.method ?? '-')
      }
      sentry.captureException(error)
    })
  }
  console.error(error, message)
}


export function logWarning(message) {
  if (message && sentry && maxWarningsToLog-- > 0) {
    sentry.captureMessage(message)
  }
  console.warn(message)
}


export function logBreadcrumb(message, data) {
  if (sentry) {
    sentry.addBreadcrumb({message, data})
  }
}


/**
 * Note: standard Error properties name/stack/message are not enumerable so JSON.stringify() does not
 * include them. We copy them to a new object so they are retained.
 */
function stringifyException(error) {
  const type = error?.constructor?.name ?? typeof error
  const copy = JSON.parse(JSON.stringify(error))
  if (copy && typeof copy === 'object') {
    copy.message = error.message
    copy.stack = error.stack
    copy.name = error.name
  }
  return JSON.stringify({ errorType: type, errorData: copy })
}


function beforeSend(event, hint) {
  const err = hint.originalException ?? hint.syntheticException
  const type = err?.name ?? ''
  const msg = err?.message ?? ''
  const stack = err?.stack ?? ''

  // Filter 3rd party errors, and things that are unfixable (like your phone losing network fo a while)
  if (
    // In javascript it is possible to throw null/undefined. Good luck debugging with no info at all.
    err == null

    // Permutive. Throws raw strings
    || (err === 'Failed to track Pageview. Network request failed')
    || (err === 'Failed to track Pageview. Network request timed out')
    || (err === 'Failed to track Pageview. A validation check carried out by the server did not succeed.')
    || (err === 'Failed to track PrebidAuctions. Network request failed')
    || (err === 'Failed to track PrebidAuctions. Network request timed out')
    || (err === 'Failed to track PrebidAuctions. A validation check carried out by the server did not succeed.')

    // LastPass extension (https://github.com/WordPress/openverse/issues/524)
    || (err === 'Not implemented on this platform')

    // No idea where this comes from. No stack trace so impossible to figure out
    || (type === 'AbortError' && msg === 'AbortError' && stack === '')
    || (type === 'AbortError' && msg === 'The operation was aborted.' && stack === '')

    // Video errors. We don't have videos of our own so these are always ads
    || (type === 'ReferenceError' && msg === 'obtainVideoInfos is not defined')
    || (type === 'NotSupportedError' && msg === 'Failed to load because no supported source was found.')
    || (type === 'AbortError' && msg.includes('The play() request was interrupted'))
    || (type === 'AbortError' && msg.includes('The fetching process for the media resource was aborted'))

    // Random errors from ads
    || (type === 'TypeError' && msg.includes('this.placement.getContainer()'))
    || (type === 'TypeError' && msg === 'this._resizeCallback is not a function')
    || (type === 'TypeError' && msg === 'Cannot redefine property: googletag')
    || (type === 'TypeError' && msg === 'Load failed' && stack === '')
    || (type === 'TypeError' && msg === 'Failed to fetch' && stack.includes('https://acdn.adnxs-simple.com/'))
    || (type === 'SyntaxError' && msg.includes('div:has(> iframe[id=') && msg.includes('is not a valid selector'))
    || (type === 'SyntaxError' && msg.includes(':is([id*=\'gpt-\'])') && msg.includes('is not a valid selector'))
    || (type === 'TypeError' && msg.includes('null') && stack.includes('browser_notify_position'))
    || (type === 'TypeError' && msg.includes('null') && stack.includes('UnitViewabilityObserver'))
    || (type === 'TypeError' && msg.includes('null') && stack.includes('hideCertainAdElements'))
    || (type === 'TypeError' && msg.includes('null') && stack.includes('hideIABFriendlyIframe'))
    || (type === 'TypeError' && msg.includes('null') && stack.includes('frame.onload'))
    || (type === 'TypeError' && msg.includes('null') && stack.includes('getViewportGeometry'))
    || (type === 'TypeError' && msg.includes('null') && stack.includes('IframePlacement'))
    || (type === 'TypeError' && msg.includes('null') && stack.includes('Placement.getContentWindow'))
    || (type === 'TypeError' && msg.includes('this.kdmw is not a function')) // nothing usable in stack
    || (type === 'TypeError' && msg === 'the given value is not a Promise' && stack === 'TypeError: the given value is not a Promise')
    || (type === 'Error' && msg.includes('contentWindow') && stack.includes('IframePlacement'))
    || (type === 'Error' && msg === 'feature named `clickToLoad` was not found' && stack === '')

    // Bunch of errors from https://target.digitalaudience.io/bakery/scripts/da.js
    || (type === 'TypeError' && msg === 'Cannot read property \'bake\' of undefined')
    || (type === 'TypeError' && msg === 'Cannot read properties of undefined (reading \'bake\')')
    || (type === 'TypeError' && msg === 'undefined is not an object (evaluating \'window.__da.bake\')')
    || (type === 'TypeError' && msg === 'window.__da is undefined')
    || (type === 'TypeError' && msg === 'Failed to fetch' && stack.includes('https://target.digitalaudience.io/bakery/'))

    // No idea. But we don't have stupid syntax errors
    || (type === 'SyntaxError' && msg === 'Unexpected token \'else\'')
    || (type === 'SyntaxError' && msg === 'Unexpected identifier \'http\'')
    || (type === 'SyntaxError' && msg === 'Unexpected identifier \'https\'')

    // Network connection errors
    || (type === 'AxiosError' && err.code === 'ETIMEDOUT')
    || (type === 'AxiosError' && err.code === 'ERR_NETWORK')
    || (type === 'AxiosError' && err.code === 'ECONNABORTED')


    // Google analytics. Possibly adblocker blocking it
    || (type === 'TypeError' && msg === 'NetworkError when attempting to fetch resource.' && stack === '')

    // IOS/OSX WebView. Whatever this error is we never call postMessage in our code
    || (type === 'Error' && msg === 'WKWebView API client did not respond to this postMessage' && stack === '')

    // Another unknown IOS/OSX postMessage
    || (type === 'SyntaxError' && msg === 'The string did not match the expected pattern.' && stack.includes('postMessage@[native code]'))

    // Unhandled promise rejection in Mobile Safari. No idea what causes it but doesn't seem like our fault
    || (type === 'Error' && msg === 'Invalid call to runtime.sendMessage(). Tab not found.' && stack === '')

    // Odd GTM errors that are not ours to fix
    || (type === 'TypeError' && msg === 'Failed to fetch' && stack.includes('https://www.googletagmanager.com/'))
    || (type === 'SecurityError' && msg === 'The operation is insecure.' && stack.includes('https://www.googletagmanager.com/'))
    || (type.includes('NS_ERROR_') && msg === '' && stack.includes('https://www.googletagmanager.com/'))
  ) {
    return null
  }

  // This is a bot masquerading as Chrome 66. No reason to log errors
  if (canUseDOM && window?.navigator?.userAgent === 'Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3359.117 Safari/537.36') {
    return null
  }

  // Sentry drops non-standard properties from exceptions so we save them as attachments
  hint.attachments ??= []
  if (hint.originalException) {
    hint.attachments.push({filename: 'originalException.json', data: stringifyException(hint.originalException), contentType: 'application/json'})
  }
  if (hint.syntheticException) {
    hint.attachments.push({filename: 'syntheticException.json', data: stringifyException(hint.syntheticException), contentType: 'application/json'})
  }

  return event
}
