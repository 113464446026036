import { createSelector } from 'reselect'
import groupedConnect from '../lib/groupedConnect'

const selectMostReadItems = state => state.items.mostRead
const selectMostVotedItems = state => state.items.mostVoted

/**
 * The properties for the most read items.
 * @returns {Object} The properties for the most read items.
 */
export const selectMostReadProps = createSelector(
  selectMostReadItems,
  (state) => state,
  (mostRead, state) => {
    return groupedConnect(state, mostRead, 'Luetuimmat', '/luetuimmat')
  }
)

/**
 * @returns {Object} The properties for the most voted items.
 */
export const selectMostVotedProps = createSelector(
  selectMostVotedItems,
  (state) => state,
  (mostVoted, state) => {
    return groupedConnect(state, mostVoted, 'Äänestetyimmät', '/aanestetyimmat')
  }
)

/** @return {boolean} */
export const selectMostReadShouldRefresh = state => state.items.mostRead.get('shouldRefresh')

/** @return {boolean} */
export const selectMostVotedShouldRefresh = state => state.items.mostVoted.get('shouldRefresh')
