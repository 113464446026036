import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import './television.pcss'
import { selectIsUserLoggedIn } from '../../selectors/userSelector'

import MainContent from '../../components/wrappers/MainContent'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import TopNotification from '../../user/TopNotification'
import TELKKU_MOB from '../../assets/telkku-mobi.png'
import TELKKU_DESK from '../../assets/telkku-desk.png'


const TelevisionView = () => {
  const loggedIn = useSelector(selectIsUserLoggedIn)

  return (
    <div className='container' id='sticky-segment-ad-bottom-boundary'>
      <div className='main-container'>
        <MainContent>
          <Helmet
            title='TV-ohjelmat - TV-opas'
            meta={ [
              { name: 'description', content: 'Amppareiden TV-opas ja Telkku ovat yhdistyneet' },
              { property: 'og:title', content: 'TV-opas \u2013 Ampparit.com' },
              { property: 'og:description', content: 'Amppareiden TV-opas ja Telkku ovat yhdistyneet' },
            ] }
          />

          <TopNotification className='tv-notification'>
            <p className='tv-notification__title'>Amppareiden TV-opas ja Telkku ovat yhdistyneet</p>
            <p className='tv-notification__text-section'>
              Ampparit ja Telkku yhdistivät voimansa, ja Amppareiden tv-opas yhdistyi Telkun kanssa.
            </p>
            <p className='tv-notification__text-section'>
              Vastedes Amppareilla ei ole omaa tv-opasta, vaan ohjelmatiedot voi jatkossa lukea Telkusta.
            </p>
            <p className='tv-notification__text-section'>
              Telkusta löydät kattavien ohjelmatietojen lisäksi tv-ohjelmiin liittyvät artikkelit.
            </p>
            <p className='tv-notification__text-section'>
              Kirjautumalla Telkkuun voit myös tallentaa omat suosikkisi muistiin ja luoda oman kanavanäkymän.&nbsp;
              { loggedIn && (
                <span>Telkussa toimii sama Alma-tunnus kuin Amppareissakin.</span>
              ) }
            </p>

            <div className='tv-notification__image-container'>
              <img src={ TELKKU_DESK } alt='Telkku työpöytä näkymä' className='tv-notification__image-container-image' />
              <img src={ TELKKU_MOB } alt='Telkku mobiili näkymä' className='tv-notification__image-container-image' />
            </div>

            <a
              className='tv-notification__link button'
              href='https://www.iltalehti.fi/telkku/tv-ohjelmat/tanaan?utm_source=internal&utm_medium=banner&utm_campaign=ampparit'
              target='_blank'
              rel='noopener noreferrer'
            >
              Siirry Telkkuun tästä
            </a>
          </TopNotification>

        </MainContent>

        <div className='sidebar-container'>
          <GeneralSidebar />
        </div>
      </div>
    </div>
  )
}

export default TelevisionView
