import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSources } from './sourcesActions'
import Loading from '../../components/util/Loading'
import MainContent from '../../components/wrappers/MainContent'
import { selectSourcesOrderedByName, selectSourceLoading } from '../../selectors/sourcesSelector'
import './infoViews.pcss'

const SourcesView = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectSourceLoading)
  const sources = useSelector(selectSourcesOrderedByName)

  useEffect(() => {
    dispatch(fetchSources(60))
  }, [dispatch])

  return (
    <MainContent>
      <article className='info-page'>
        <Helmet title='Lähteet' />

        <h1>Kaikki lähteet ({ sources.size }&nbsp;kpl)</h1>

        <Loading loading={ loading || sources.size === 0 }>
          <div className='sources-list'>
            { sources.map((source) => (
              <div key={ source.id }>
                <a href={ source.link } rel='noopener noreferrer'>
                  { source.name }
                </a>
              </div>
            )) }
          </div>
        </Loading>
      </article>
    </MainContent>
  )
}

export default SourcesView
