import 'font-awesome/css/font-awesome.min.css' // Import FA first, it has some styles that may inadvertantly override ours it FA's CSS comes later
import config from '../config/config'
import React from 'react'
if (config.webpack.enableWhyDidYouRender) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    //include: [/./],
  })
}
import axios from 'axios'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { browserHistory, match, Router } from 'react-router'
import { setGlobalDevModeChecks } from 'reselect'
import { syncHistoryWithStore } from 'react-router-redux'
import { trigger } from 'redial'
import { Cookies, CookiesProvider } from 'react-cookie'
import getRoutes from './routes'
import createReduxStore from './store'
import moment from 'moment-timezone'
import Immutable from 'immutable'
import './styles/styles.pcss'
import { setPageBodyClass } from './views/pages'
import { initSentry, logException } from './sentry'
import { generateDigitalData, sendDataLayer } from './almaDatalayer'
import Modal from 'react-modal'
import { pauseAds, resumeAds } from './ad/AlmaAd'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/500-italic.css'
import '@fontsource/open-sans/600-italic.css'
import '@fontsource/open-sans/700-italic.css'

// To have webpack emit asset files they need to referenced somewhere in compilation tree.
// This is that somewhere. We don't directly use these files but have to import them anyway
// so they get placed in /dist/. The placement dir of each file type is set in webpack.config.js
import './assets/favicon.ico'
import './assets/robots.txt'
import './assets/google8c6fd909d220f8ec.html'
import './assets/email-header.gif'

Modal.setAppElement('#react-view')

axios.defaults.timeout = 30000
axios.defaults.transitional.clarifyTimeoutError = true

try {
  setGlobalDevModeChecks({
    inputStabilityCheck: 'always',
    identityFunctionCheck: 'always',
  })


  if (config.webpack.enableSentryIO) {
    const Sentry = require('@sentry/react')
    initSentry(Sentry)
  }
  if (config.webpack.enableReduxDevtools) {
    const installDevTools = require('immutable-devtools')
    installDevTools(Immutable)
  }

  moment.tz.setDefault('Europe/Helsinki')

  const store = createReduxStore(
    window.__INITIAL_STATE__,
    browserHistory,
  )
  delete window.__INITIAL_STATE__
  const routes = getRoutes(store)
  const history = syncHistoryWithStore(browserHistory, store)
  let pageViewNumber = 0

  // Trigger data fethes, ad reloads, etc. on history changes. Callback also triggers on initial
  // page view, so skip any steps that must be done only on actual history *changes*
  history.listen(location => {
    pageViewNumber++
    pauseAds()
    setPageBodyClass(location.pathname)

    match({routes, location}, async (error, redirectLocation, renderProps) => {
      if (!renderProps) return

      // On first pageview all data has been loaded on server side, no need to fetch on client
      if (pageViewNumber > 1) {
        // Page does not automatically scroll to top when navigating to same page, for example
        // when user is on front page and hits Ampparit logo.
        window.scrollTo(0, 0)

        const locals = {
          path: renderProps.location.pathname,
          query: renderProps.location.query,
          params: renderProps.params,
          getState: store.getState,
          cookies: new Cookies(),
          dispatch: store.dispatch,
        }
        await trigger('fetch', renderProps.components, locals)
      }

      window.digitalData = generateDigitalData(store.getState())
      sendDataLayer()

      if (window.initAlmaAds) {
        window.initAlmaAds()
        resumeAds()
      }
    })
  })

  // Hydrate the app
  match({routes, history}, (error, redirectLocation, renderProps) => {
    const container = window.document.getElementById('react-view')
    const root = createRoot(container)
    root.render(
      <CookiesProvider cookies={ new Cookies() }>
        <Provider store={ store } history={ history }>
          <Router { ...renderProps } />
        </Provider>
      </CookiesProvider>
    )
  })
} catch (error) {
  logException(error)
}
