import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMostRead } from '../../components/item/itemsActions'
import GroupedItems from './GroupedItems'
import { PostContentAd } from '../../ad/Ad'
import { fetchPopular } from '../../components/item/popularActions'
import { selectMostReadProps, selectMostReadShouldRefresh } from '../../selectors/topNewsSelector'

const MostReadView = () => {
  const dispatch = useDispatch()
  const props = useSelector(selectMostReadProps)
  const shouldRefresh = useSelector(selectMostReadShouldRefresh)

  useEffect(() => {
    dispatch(fetchMostRead())
    dispatch(fetchPopular(300))
  }, [dispatch])

  useEffect(() => {
    if (shouldRefresh) {
      dispatch(fetchMostRead())
    }
  }, [shouldRefresh, dispatch])

  return (
    <React.Fragment>
      <GroupedItems
        { ...props }
        dispatch={ dispatch }
        title='Luetuimmat | Uutiset'
        meta={ [
          {name: 'description', content: 'Ampparit.com kertoo, mitkä ovat Suomen suosituimpia uutisia juuri nyt. Näet Luetuimmat-listalta yhdellä silmäyksellä, mitä uutisia Suomi lukee. Se kattavin uutistarjonta \u2013 Ampparit.com'},
          {property: 'og:title', content: 'Luetuimmat \u2013 Ampparit.com'},
          {property: 'og:description', content: 'Ampparit.com kertoo, mitkä ovat Suomen suosituimpia uutisia juuri nyt. Näet Luetuimmat-listalta yhdellä silmäyksellä, mitä uutisia Suomi lukee. Se kattavin uutistarjonta \u2013 Ampparit.com'},
        ] }
      />
      <PostContentAd />
    </React.Fragment>
  )
}

export default MostReadView
