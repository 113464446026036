import axios from 'axios'
import { canUseDOM } from 'exenv'
import { apiUrl, appVersionUrl } from '../../config/config'
import { listSize } from '../lib/items'


class BadApiResponseError extends Error {
  constructor(message, response) {
    super(message)
    this.name = 'BadApiResponseError'
    this.method = response?.config?.method
    this.url = response?.config?.url
    this.status = response?.status
    this.headers = response?.headers
    this.note = 'This error generally happens when the API call is intercepted by a captive portal or similar, ' +
                'and the response is not what we expect. There is not much we can do to resolve such issues. ' +
                'We currently log this error to Sentry to keep track of how often it happens. The only "fix" ' +
                'we could do is call window.location.reload() to reload the page so user can re-authenticate ' +
                'in the captive portal instead of things just not working for no apparent reason. This however ' +
                'could lead to reload loops if we ever have bug in our code that causes this error to be thrown.'
  }
}

/**
 * Check for bad responses caused by captive portals. These are rare because we use HTTPS, but do happen.
 * Throwing here is better than letting bad responses propagate and cause random errors all over the place.
 * @todo Detect cloudflare's 403s and other similar responses and handle them gracefully.
 * @todo Make sure POST/PUT/DELETE responses are all timestamped and add validation for them.
 * @param {import('axios').AxiosResponse} response
 * @throws {BadApiResponseError}
 * @returns {void}
 */
function validateResponse(response) {
  const is200 = response?.status === 200
  const notJSON = !response?.headers['content-type']?.startsWith('application/json')
  const notTimestamped = !(response?.data?.timestamp > 0)
  if (is200 && (notJSON || notTimestamped)) {
    throw new BadApiResponseError('Unexpected response type from API', response)
  }
}



function apiGet(path, params, jwt) {
  // Manually passing JTW for server-side rendering. SSR does not do any POST/etc requests, so we only
  // set it for GET requests. For client side requests JTW is always automatically passed via cookie.
  const headers = !canUseDOM && jwt ? { 'Authorization': `Bearer ${jwt}` } : {}
  return axios({
    method: 'GET',
    url: apiUrl + path,
    params: params ?? {},
    headers,
  }).then(response => {
    validateResponse(response)
    return response.data
  })
}

function apiPost(path, data) {
  return axios({
    method: 'POST',
    url: apiUrl + path,
    data: data ?? {},
  }).then(response => {
    return response.data
  })
}

function apiPut(path, data) {
  return axios({
    method: 'PUT',
    url: apiUrl + path,
    data: data ?? {},
  }).then(response => {
    return response.data
  })
}

function apiDelete(path) {
  return axios({
    method: 'DELETE',
    url: apiUrl + path,
  }).then(response => {
    return response.data
  })
}


export function getAppVersion() {
  return axios.get(appVersionUrl).then(response => {
    validateResponse(response)
    return response.data
  })
}



export function setKeyValue(key, value) {
  return apiPut(`/profile/keyvalue/${key}`, {value})
}


export function getLogo() {
  return apiGet('/sitelogo/')
}

export function getTickers(tickerSlug = null) {
  const params = tickerSlug ? { ticker: tickerSlug } : {}
  return apiGet('/tickers/', params)
}

export function postClick(itemId) {
  return apiPost(`/items/${itemId}/click`)
}

export function postVote(itemId, voteType) {
  return apiPut(`/items/${itemId}/vote`, {vote: voteType})
}

export function postReport(itemId, reportType) {
  return apiPut(`/items/${itemId}/report`, {report: reportType})
}

export function getStatsForItems(itemIds) {
  return apiPost('/items/stats', {itemIds: itemIds})
}

export function getItem(itemId) {
  return apiGet(`/items/${itemId}`)
}


function getItems(path, jwt, { timestamp, batch, limit = listSize, blacklisted_categories, blacklisted_tags, whitelisted_categories, whitelisted_tags, language, paywalled, category, tag } = {}) {
  const params = {
    limit: limit,
  }
  if (timestamp) {
    params.maxTimestamp = timestamp + 1
  }
  if (batch) {
    params.batch = batch
  }
  if (blacklisted_categories) {
    params.blacklisted_categories = blacklisted_categories
  }
  if (blacklisted_tags) {
    params.blacklisted_tags = blacklisted_tags
  }
  if (whitelisted_categories) {
    params.whitelisted_categories = whitelisted_categories
  }
  if (whitelisted_tags) {
    params.whitelisted_tags = whitelisted_tags
  }
  if (language) {
    params.language = language
  }
  if (paywalled) {
    params.paywalled = paywalled
  }
  if (category) {
    params.category = category
  }
  if (tag) {
    params.tag = tag
  }
  const userType = jwt ? 'user' : 'anon'
  return apiGet(`/itemlists/${userType}/${path}`, params, jwt)
}

export function getNewest(jwt, params) {
  return getItems('newest', jwt, params)
}

export function getUserItems(jwt, params) {
  return getItems('followed', jwt, params)
}

export function getFeatured(jwt, params) {
  return getItems('featured', jwt, params)
}

export function getPaywalled(jwt, params) {
  return getItems('paywalled', jwt, params)
}

export function getMostRead(jwt, params) {
  return getItems('top-read', jwt, params)
}

export function getMostVoted(jwt, params) {
  return getItems('top-voted', jwt, params)
}

export function getItemsForCategory(jwt, categorySlug, params) {
  return getItems(`category/${categorySlug}`, jwt, params)
}

export function getItemsForTag(jwt, tagSlug, params) {
  return getItems(`tag/${tagSlug}`, jwt, params)
}

export function getPopular(jwt, params) {
  return getItems('sidebar-popular', jwt, params)
}

export function getUserFeedItems(jwt, params) {
  return getItems('sidebar-user-feeds', jwt, params)
}

export function getTrending() {
  return apiGet('/trends')
}

export function getBreakingNews(jwt, params) {
  return getItems('breaking', jwt, params)
}

export function searchItems(term, page) {
  const params = {
    q: term,
    limit: listSize,
    page: page || '1',
  }
  return apiGet('/search/', params)
}

export function getCategories() {
  return apiGet('/categories')
}

export function getAllTags() {
  return apiGet('/tags/all')
}
export function getSources() {
  return apiGet('/sources')
}

export function sendAdminEmail(subject, replyto, postData) {
  postData._subject = subject
  postData._replyto = replyto
  return apiPost('/adminemail/', postData)
}

export function getReadyMadeProfiles() {
  return apiGet('/readymadeprofiles')
}

export function setUserVoteFilter(threshold) {
  return apiPut('/profile/vote-filter', { threshold })
}


export function addUserWordFilter(filter) {
  return apiPut(`/profile/word-filters/${encodeURIComponent(filter)}`, null)
}
export function removeUserWordFilter(filter) {
  return apiDelete(`/profile/word-filters/${encodeURIComponent(filter)}`)
}


export function addUserSavedSearch(searchTerm) {
  return apiPut(`/profile/searches/${encodeURIComponent(searchTerm)}`, null)
}
export function removeUserSavedSearch(searchTerm) {
  return apiDelete(`/profile/searches/${encodeURIComponent(searchTerm)}`)
}


export function addUserFeed(url) {
  return apiPost('/profile/feeds/', {url})
}
export function updateUserFeed(feedId, categoryId) {
  return apiPut(`/profile/feeds/${feedId}`, {categoryId})
}
export function removeUserFeed(feedId) {
  return apiDelete(`/profile/feeds/${feedId}`)
}


export function addWhitelistTag(tagId) {
  return apiPost(`/profile/tags/whitelisted/${tagId}`, null)
}
export function removeWhitelistTag(tagId) {
  return apiDelete(`/profile/tags/whitelisted/${tagId}`)
}
export function addBlacklistTag(tagId) {
  return apiPost(`/profile/tags/blacklisted/${tagId}`, null)
}
export function removeBlacklistTag(tagId) {
  return apiDelete(`/profile/tags/blacklisted/${tagId}`)
}


export function addBlacklistSource(sourceId) {
  return apiPost(`/profile/sources/blacklisted/${sourceId}`, null)
}
export function removeBlacklistSource(sourceId) {
  return apiDelete(`/profile/sources/blacklisted/${sourceId}`)
}


export function addSubscribeSource(sourceId) {
  return apiPost(`/profile/sources/subscriptions/${sourceId}`, null)
}
export function removeSubscribeSource(sourceId) {
  return apiDelete(`/profile/sources/subscriptions/${sourceId}`)
}


export function addUserSubscribedLanguage(langSlug) {
  return apiPost(`/profile/languages/${langSlug}`, null)
}
export function removeUserSubscribedLanguage(langSlug) {
  return apiDelete(`/profile/languages/${langSlug}`)
}


export function addWhitelistCategory(categoryId) {
  return apiPost(`/profile/categories/whitelisted/${categoryId}`, null)
}
export function removeWhitelistCategory(categoryId) {
  return apiDelete(`/profile/categories/whitelisted/${categoryId}`)
}
export function addBlacklistCategory(categoryId) {
  return apiPost(`/profile/categories/blacklisted/${categoryId}`, null)
}
export function removeBlacklistCategory(categoryId) {
  return apiDelete(`/profile/categories/blacklisted/${categoryId}`)
}


export function getRecommendedCategories(jwt) {
  return apiGet('/recommendations/categories/', null, jwt)
}
export function incRecommendedCategoriesDisplay(categoryId) {
  return apiPost(`/recommendations/categories/${categoryId}/display`, null)
}
export function setRecommendedCategoriesAccept(categoryId) {
  return apiPost(`/recommendations/categories/${categoryId}/accept`, null)
}
export function setRecommendedCategoriesReject(categoryId) {
  return apiPost(`/recommendations/categories/${categoryId}/reject`, null)
}


export function getWeather(params) {
  return apiGet('/weather/', params)
}
export function searchWeatherLocations(searchString) {
  return apiGet('/weather/locations', {query: searchString})
}

export function addWeatherLocation(locationId) {
  return apiPost(`/profile/weather/locations/${locationId}`, null)
}
export function setWeatherLocations(locationIds) {
  return apiPut(`/profile/weather/locations/${locationIds.join(',')}`, null)
}
export function setDefaultWeatherLocation(locationId) {
  return apiPut(`/profile/weather/default-location/${locationId}`, null)
}

export function forgotUserPassword(email) {
  return apiPost('/profile/forgot-password', {email})
}
export function newUserPassword(token, password) {
  return apiPut('/profile/new-password', {token, password})
}

export function loginWithAlmaTunnus(accessToken) {
  return apiPost('/profile/almalogin', {id_token: accessToken})
}

export function almaTunnusRegister(accessToken, tempProfile) {
  const postData = {
    id_token: accessToken,
  }
  if (tempProfile) {
    postData.whitelisted_categories = tempProfile.whitelistedCats.join(',')
    postData.whitelisted_tags = tempProfile.whitelistedTags.join(',')
    postData.blacklisted_categories = tempProfile.blacklistedCats.join(',')
    postData.blacklisted_tags = tempProfile.blacklistedTags.join(',')
  }
  return apiPost('/profile/almaregister', postData)
}

export function almaTunnusMigrate(accessToken, emailOrUsername, password) {
  const postData = {
    id_token: accessToken,
    username: emailOrUsername,
    password: password,
  }
  return apiPost('/profile/almamigrate', postData)
}


export function logoutAmpparitUser() {
  return apiPost('/profile/logout/')
}

export function getUser(jwt) {
  return apiGet('/profile/', null, jwt)
}
