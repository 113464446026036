import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Loading from '../components/util/Loading'
import Item from '../components/item/Item'
import MoreContent from '../components/widgets/MoreContent'
import { fetchMostRead } from '../components/item/itemsActions'
import { setStatus } from '../status/statusActions'
import SidebarMenu from '../components/layout/SidebarMenu'
import GeneralSidebar from '../components/layout/GeneralSidebar'
import '../styles/not-found.pcss'
import MainContent from '../components/wrappers/MainContent'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { selectNotFoundViewData } from '../selectors/notFoundSelectors'

const NotFoundView = ({ loggedIn, dispatch }) => {

  const { items, loading, timestamp } = useSelector(selectNotFoundViewData)

  useEffect(() => {
    dispatch(setStatus(404))
    dispatch(fetchMostRead())
  }, [dispatch])

  const hasItems = items.size > 0

  return (
    <div className='container'>
      <Helmet title='Sivua ei löydy' />

      <div className='main-container'>
        <SidebarMenu />

        <div id='sticky-ad-bottom-boundary' >
          <MainContent>
            <div className='not-found-container'>
              <div className='not-found-icon fa fa-chain-broken' />
              <div>
                <h1>Hups, minne eksyit?</h1>
                <p>
                  { hasItems ?
                    `Täältä ei löydy uutisia eikä sisältöä, mutta ei hätää!
                    Valitse valikosta aihe, hae uutisia tai tutki allaolevista uutisista
                    jotain kiinnostavaa ja jatka uusimpien uutisten seuraamista:`
                    :
                    `Täältä ei löydy uutisia eikä sisältöä, mutta ei hätää!
                    Valitse valikosta aihe tai käytä hakua löytääksesi jotain
                    kiinnostavaa ja jatka uusimpien uutisten seuraamista.`
                  }
                </p>
              </div>
            </div>

            { (hasItems || loading) && (
              <section className='items-list'>
                <h3>Luetuimpia Amppareissa juuri nyt</h3>

                <Loading loading={ loading }>
                  { items.map(item => (
                    <Item
                      key={ item.get('id') }
                      item={ item }
                      timestamp={ timestamp }
                      dispatch={ dispatch }
                      loggedIn={ loggedIn }
                    />
                  )) }
                </Loading>
              </section>
            ) }
          </MainContent>

          <MoreContent title='Lisää uutisia odottaa' />
        </div>

        <div className='sidebar-container'>
          <GeneralSidebar />
        </div>
      </div>
    </div>
  )
}

export default NotFoundView

NotFoundView.propTypes = { // eslint-disable-line react/prefer-exact-props
  loggedIn: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
}
