import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMostVoted } from '../../components/item/itemsActions'
import GroupedItems from './GroupedItems'
import { PostContentAd } from '../../ad/Ad'
import { fetchPopular } from '../../components/item/popularActions'
import { selectMostVotedProps, selectMostVotedShouldRefresh } from '../../selectors/topNewsSelector'


const MostVotedView = () => {
  const dispatch = useDispatch()
  const props = useSelector(selectMostVotedProps)
  const shouldRefresh = useSelector(selectMostVotedShouldRefresh)

  useEffect(() => {
    dispatch(fetchMostVoted())
    dispatch(fetchPopular(300))
  }, [dispatch])

  useEffect(() => {
    if (shouldRefresh) {
      dispatch(fetchMostVoted())
    }
  }, [shouldRefresh, dispatch])

  return (
    <React.Fragment>
      <GroupedItems
        { ...props }
        dispatch={ dispatch }
        title='Äänestetyimmät | Uutiset'
        meta={ [
          {name: 'description', content: 'Ampparit.com kertoo, mitkä ovat Suomen suosituimpia uutisia juuri nyt. Näet Äänestetyimmät-listalta yhdellä silmäyksellä, mitä uutisia Suomi lukee. Se kattavin uutistarjonta \u2013 Ampparit.com'},
          {property: 'og:title', content: 'Äänestetyimmät \u2013 Ampparit.com'},
          {property: 'og:description', content: 'Ampparit.com kertoo, mitkä ovat Suomen suosituimpia uutisia juuri nyt. Näet Äänestetyimmät-listalta yhdellä silmäyksellä, mitä uutisia Suomi lukee. Se kattavin uutistarjonta \u2013 Ampparit.com'},
        ] }
      />
      <PostContentAd />
    </React.Fragment>
  )
}

export default MostVotedView
