/**
 * Client config file is compiled in to final client bundle by webpack. To be able to use
 * one single artifact in multiple environments, we need to have configs for all of them
 * in them here. The correct config to use is then selected based on the APP_ENV environment
 * variable (SSR) and window.location.hostname (client). Being compiled in also means that
 * this file can never contain any secrets or other sensitive information.
 */


// Defaults are set for prod, so if any detection fails only dev sites can break.
let ssrEnpdoint = 'http://localhost'
let webEndpoint = 'https://www.ampparit.com'
let env = 'production'
let overrides = {}

// Detect running environment
const isSSR = (typeof window === 'undefined')
const appEnv = isSSR ? process.env.APP_ENV : ''
const hostname = isSSR ? '' : window.location.hostname

// Override defaults based on detected environment
if (appEnv === 'staging' || hostname.includes('stage')) {
  env = 'stage'
  ssrEnpdoint = 'http://localhost'
  webEndpoint = 'https://stage.ampparit.com'
} else if (appEnv === 'surrur' || hostname.includes('surrur')) {
  env = 'surrur'
  ssrEnpdoint = 'http://ampparit-devdocker:8888'
  webEndpoint = 'https://surrur.ampparit.com'
  overrides.displayAds = false
  overrides.gtmContainerId = 'GTM-M7738WB'
} else if (appEnv === 'local' || hostname.includes('local')) {
  env = 'local'
  ssrEnpdoint = 'http://nginx'
  webEndpoint = 'https://local.ampparit.com'
  overrides.displayAds = false
  overrides.gtmContainerId = 'GTM-M7738WB'
  overrides.webpack = {
    enableSentryIO: false,
    enableReduxDevtools: true,
    enableWhyDidYouRender: false,
  }
}

/**
 * This is the final config object that will be exported.
 */
const config = {
  // These variables will differ depending on whether the code is executing in browser or in Node SSR:
  apiUrl: isSSR ? `${ssrEnpdoint}/api` : `${webEndpoint}/api`,
  appVersionUrl: isSSR ? `${ssrEnpdoint}/clientversion` : `${webEndpoint}/clientversion`,

  // These will always point to the "real" url, so will be be same in SRR:
  siteUrl: webEndpoint,
  socket: webEndpoint,

  // Normal config stuff
  displayAds: true,
  displayCooperationBanner: true,
  defaultLocation: 14264, // Helsinki
  facebookAppId: '133363990059595',
  gtmContainerId: 'GTM-TQZQTWT',

  // These get turned in to webpack DefinePlugin constants. If you disable a feature
  // by setting it false, the whole npm library will be excluded from final js bundle.
  // This means you cannot override them after compilation. Only in local dev, where
  // APP_ENV is already set at build time, can these be overridden from the values below.
  webpack: {
    enableSentryIO: true,
    enableReduxDevtools: false,
    enableWhyDidYouRender: false,
  },

  // See https://docs.sentry.io/platforms/javascript/configuration/options/
  sentry: {
    dsn: 'https://af232dbc502582b3980daedbee10a050@o4507293114695680.ingest.de.sentry.io/4507293123149904',
    sampleRate: 1.0,
    maxBreadcrumbs: 20,
  },

  // See https://www.npmjs.com/package/universal-cookie
  // Httponly is false because client needs programmatic access to tempProfile and other cookies.
  // For added security, login cookie's httpOnly is overridden in server.js and set to true.
  coockieOptions: {
    maxAge: 3600 * 24 * 365,
    httpOnly: false,
    secure: true,
    path: '/',
    sameSite: 'lax',
  },
  almaTunnusConfig: getAlmaTunnusConfig(env),
}



/**
 * Returns AlmaTunnus config based on environment.
 * @see https://ict-solution-catalog.almamedia.io/alma-account-core/docs/AlmaTunnus and
 * @see https://almamedia.atlassian.net/wiki/spaces/ALTU/pages/1418035254/Component+parameters
 * @param {'prod'|'stage'|'surrur'|'local'} env
 * @returns {Object}
 */
function getAlmaTunnusConfig(env) {
  if (['local', 'surrur', 'stage'].includes(env)) {
    return {
      scope: 'openid profile email offline_access',
      realm: 'Almatunnus',
      domain: 'tunnus-dev.almamedia.net',
      audience: 'https://tunnus-dev.almamedia.net',
      clientID: 'DT1ld9zvIzIDDSuAxf6TPgRVFcTk3i1l',
      redirectUri: `${webEndpoint}/`,
      selfService: 'https://itsepalvelu.tunnus-dev.almamedia.net',
    }
  }
  return {
    scope: 'openid profile email offline_access',
    realm: 'Almatunnus',
    domain: 'tunnus.almamedia.fi',
    audience: 'https://tunnus.almamedia.fi',
    clientID: '5rEguAmL88cIk7CsTXy3huAFzAWpqtwA',
    redirectUri: `${webEndpoint}/`,
    selfService: 'https://itsepalvelu.almamedia.fi',
  }
}

module.exports = Object.assign({}, config, overrides)
