import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { Helmet } from 'react-helmet'
import { Cookies, withCookies } from 'react-cookie'
import WebsocketClient from './WebsocketClient'
import { fetchBrandLogo } from '../components/menu/BrandLogoActions'
import Menu from '../components/menu/Menu'
import Footer from '../components/layout/Footer'
import { InterstitialAd, LeaderboardAd } from '../ad/Ad'
import { Ticker } from '../components/ticker/Ticker'
import { createUrl, basicWildcardMatch } from '../lib/utils'
import { fetchTrending } from '../components/item/trendingActions'
import { fetchBreakingNews } from '../components/widgets/breakingNewsActions'
import { fetchTickers } from '../components/ticker/tickerActions'
import Notifier from '../components/notification/Notifier'
import NotFoundView from '../views/NotFoundView'
import SearchBar from '../views/Search/SearchBar'
import ScrollToTopButton from '../components/ui/ScrollToTopButton'
import classnames from 'classnames'
import {
  toggleOverlay,
  fetchRecommendedCategories,
  fetchUserProfile,
} from '../user/userActions'
import LoginOverlay from '../user/LoginOverlay'
import shareLogo from '../assets/ampparit-share-logo.png'
import appleTouchIcon from '../assets/apple-touch-icon.png'
import Modals from '../components/modal/Modals'
import { fetchAppVersion } from '../status/statusActions'
import {
  selectUserEmail,
  selectIsUserLoggedIn,
  selectUserWhitelistedCategoryIds,
  selectUserBlacklistedCategoryIds,
} from '../selectors/userSelector'
import { isTempProfileModified } from '../user/user'
import { selectLocationPath, selectDeviceType } from '../selectors/statusSelector'
import { handleAppQueryParams } from '../lib/app'
import debounce from 'lodash/debounce'
import AccessibilityAnnouncer from '../components/messages/AccessibilityAnnouncer'
import { announceToScreenreader } from '../components/messages/accessibilityAnnouncerActions'
import SkipLink from '../components/ui/SkipLink'
import { updateDigitalDataClientSideAttributes } from '../almaDatalayer'
import AlmaLogin from '../user/almalogin/AlmaLogin'
import BottomNavigation from '../components/menu/BottomNavigation'
import {
  selectAlmaTunnusLoginRequested,
  selectAlmaTunnusLogoutRequested,
  selectIsAlmaTunnusEmailVerified,
  selectAlmaTunnusRegisterRequested,
} from '../selectors/almaTunnusSelectors'
import { selectTagBySlug } from '../selectors/tagsSelector'
import { refreshAllAds } from '../ad/AlmaAd'

@provideHooks({
  fetch: ({ dispatch, cookies }) => {
    return Promise.all([
      dispatch(fetchUserProfile(1200)),
      dispatch(fetchAppVersion(180)),
      dispatch(fetchTrending(300)),
      dispatch(fetchBreakingNews(300)),
      dispatch(fetchBrandLogo(3600)),
      dispatch(fetchTickers(1800, cookies)),
      dispatch(fetchRecommendedCategories(10800)),
    ])
  },
})

@connect((state, ownProps) => {
  const {
    time,
    tags,
    categories,
    status,
    user,
    search,
    tickers,
  } = state

  let tag = null
  let category = null

  if (ownProps.params.tag) {
    tag = selectTagBySlug(state, ownProps.params.tag)
  }

  if (ownProps.params.category) {
    category = categories.categories.get(ownProps.params.category)
  }

  return {
    path: selectLocationPath(state),
    timestamp: time.get('timestamp'),
    status: status.get('status'),
    tickers: tickers.tickers,
    whitelistedCategories: selectUserWhitelistedCategoryIds(state),
    blacklistedCategories: selectUserBlacklistedCategoryIds(state),
    categories: categories.categories,
    tag,
    category,
    tags: tags.tags,
    userCategories: user.get('categories'),
    loggedIn: selectIsUserLoggedIn(state),
    isSearchOpen: search.get('isOpen') || selectLocationPath(state) === '/haku',
    isLoginOverlayOpen: user.get('overlayIsOpen'),
    deviceType: selectDeviceType(state),
    emailVerified: selectIsAlmaTunnusEmailVerified(state),
    email: selectUserEmail(state),
    almaTunnusLoginRequested: selectAlmaTunnusLoginRequested(state),
    almaTunnusLogoutRequested: selectAlmaTunnusLogoutRequested(state),
    almaTunnusRegisterRequested: selectAlmaTunnusRegisterRequested(state),
  }
})
class App extends Component {
  constructor() {
    super()
    this.updateWindowDimensions = debounce(this.updateWindowDimensions, 200)
    updateDigitalDataClientSideAttributes()
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    children: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    tickers: ImmutablePropTypes.list.isRequired,
    tag: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      type: PropTypes.string,
      blacklisted: PropTypes.bool,
      whitelisted: PropTypes.bool,
    }),
    category: ImmutablePropTypes.record,
    status: PropTypes.number.isRequired,
    isSearchOpen: PropTypes.bool.isRequired,
    isLoginOverlayOpen: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
      hash: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    deviceType: PropTypes.string.isRequired,
    emailVerified: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    almaTunnusLoginRequested: PropTypes.bool.isRequired,
    almaTunnusLogoutRequested: PropTypes.bool.isRequired,
    almaTunnusRegisterRequested: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    handleAppQueryParams(this.props)
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    window.document.addEventListener('visibilitychange', this.handleDocumentVisibilityStateChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.document.removeEventListener('visibilitychange', this.handleDocumentVisibilityStateChange)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      setTimeout(function() {
        this.props.dispatch(announceToScreenreader(window.document.title))
        if (window.document.activeElement?.tagName !== 'INPUT') {
          this.focusElement.focus()
        }
      }.bind(this), 500)
    }

    if (this.props.path !== prevProps.path) {
      this.props.dispatch(toggleOverlay(false))
    }
  }

  updateWindowDimensions = () => {
    updateDigitalDataClientSideAttributes()
  }

  timeoutId = null
  TIMEOUT_DURATION = 30000 // 30 seconds
  shouldRefreshAds = false

  handleDocumentVisibilityStateChange = () => {
    if (!window.document.hidden) {
      this.props.dispatch(fetchAppVersion(120))

      // Refresh ads if the document was hidden and ads were pending refresh
      if (this.shouldRefreshAds && window.initAlmaAds) {
        window.initAlmaAds()
        refreshAllAds()
        this.props.dispatch({type: 'ALMA_ADS_REFRESHED'}) // @todo refactor or remove analytics when moving ads-reload to own component
      }

      // Clear the timeout
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }

      this.shouldRefreshAds = false
    } else {
    // Set a timeout when the document becomes hidden
      this.timeoutId = setTimeout(() => {
        this.shouldRefreshAds = true
      }, this.TIMEOUT_DURATION)
    }
  }

  render() {
    const {
      children,
      path,
      timestamp,
      tickers,
      tag,
      category,
      status,
      isSearchOpen,
      isLoginOverlayOpen,
      location,
      dispatch,
      cookies,
      deviceType,
      emailVerified,
      email,
      loggedIn,
      almaTunnusLoginRequested,
      almaTunnusLogoutRequested,
      almaTunnusRegisterRequested,
    } = this.props


    const isFrontPage = path === '/'

    const naviTicker = tickers.find(ticker =>
      !ticker.get('closed') && ticker.get('position') === 'navi' && ticker.get('devices').contains(deviceType) &&
      ticker.get('pages').some(page => basicWildcardMatch(page, path))
    )

    const bottomTicker = tickers.find(ticker =>
      !ticker.get('closed') && ticker.get('position') === 'bottom' && ticker.get('devices').contains(deviceType) &&
      ticker.get('pages').some(page => basicWildcardMatch(page, path))
    )

    return (
      <div>
        <Helmet
          title='Uutiset'
          meta={ [
            {name: 'description', content: 'Ampparit.com kokoaa yhteen koko Suomen uutistarjonnan. Löydä kätevästi kaikki tuoreimmat uutiset ilmaiseksi Amppareiden uutispalvelusta! Se kattavin uutistarjonta \u2013 Ampparit.com'},
            {property: 'og:title', content: 'Uutiset \u2013 Ampparit.com'},
            {property: 'og:image', content: createUrl(shareLogo)},
            {property: 'og:description', content: 'Ampparit.com kokoaa yhteen koko Suomen uutistarjonnan. Löydä kätevästi kaikki tuoreimmat uutiset ilmaiseksi Amppareiden uutispalvelusta! Se kattavin uutistarjonta \u2013 Ampparit.com'},
            {property: 'og:type', content: 'website'},
            {property: 'og:url', content: createUrl(path)},
            {name: 'theme-color', content: '#222'},
            {name: 'msapplication-navbutton-color', content: '#222'},
          ] }
          link={ [
            {rel: 'canonical', href: createUrl(path)},
            {rel: 'apple-touch-icon', href: createUrl(appleTouchIcon)},
          ] }
        >
          { isFrontPage &&
            <script type='application/ld+json'>{ `
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "url": "${createUrl('/')}",
                "potentialAction": {
                  "@type": "SearchAction",
                  "target": "${createUrl('/haku') + '?q={search_term_string}'}",
                  "query-input": "required name=search_term_string"
                }
              }`
            }
            </script>
          }

          <script type='application/ld+json'>{ `
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${createUrl('/')}",
              "logo": "${shareLogo}",
              "sameAs": [
                "https://www.facebook.com/ampparit/",
                "https://twitter.com/ampparitcom"
              ],
              "email": "ampparit@ampparit.com"
            }, {
              "@context": "https://schema.org",
              "@type": "PostalAddress",
              "addressCountry": "FI",
              "addressLocality": "Helsinki",
              "name": "Alma Media Oyj",
              "postalCode": "00101",
              "streetAddress": "Alma-talo, Alvar Aallon katu 3 C"
            } `
          }
          </script>
        </Helmet>

        <LoginOverlay
          emailVerified={ emailVerified }
          loggedIn={ loggedIn }
          onToggle={ this.handleOverlayToggle }
          isOpen={ isLoginOverlayOpen }
          dispatch={ dispatch }
          email={ email }
        />

        <AlmaLogin
          dispatch={ dispatch }
          loggedIn={ loggedIn }
          loginRequested={ almaTunnusLoginRequested }
          logoutRequested={ almaTunnusLogoutRequested }
          registerRequested={ almaTunnusRegisterRequested }
        />

        <AccessibilityAnnouncer />
        <Notifier />

        <Modals />

        <InterstitialAd path={ path } />

        <p ref={ this.bindFocusElementRef } tabIndex='-1' className='sr-only'>
          Olet sivun alussa
        </p>
        <SkipLink target='#content' />

        <Menu
          path={ path }
          timestamp={ timestamp }
          searchOpen={ isSearchOpen }
          isOpen={ isLoginOverlayOpen }
          loggedIn={ loggedIn }
          dispatch={ dispatch }
        />

        { naviTicker && (
          <Ticker ticker={ naviTicker } dispatch={ dispatch } />
        ) }

        <div className={ classnames('outer-container',
          {'blur': isLoginOverlayOpen}) }
        >
          <div className='search-container'>
            <SearchBar
              isOpen={ isSearchOpen }
              path={ path }
            />
          </div>

          <div className='ad-container'>
            { !path.startsWith('/asetukset') && path !== '/kirjaudu' &&
              <LeaderboardAd path={ path } />
            }
          </div>

          <noscript>
            Selaimessasi on Javascript suoritus estetty. Ota Javascript käyttöön, jotta voit hyödyntää kaikkia tämän sivuston toimintoja.
          </noscript>

          { status === 404 ? (
            <NotFoundView
              dispatch={ dispatch }
              loggedIn={ loggedIn }
            />
          ) : children && React.cloneElement(children, {
            path,
            tempProfileModified: isTempProfileModified(cookies),
            tag,
            category,
            location,
            dispatch: dispatch,
            loggedIn,
          }) }

          { bottomTicker != null && (
            <Ticker ticker={ bottomTicker } dispatch={ dispatch } />
          ) }
        </div>

        <BottomNavigation
          path={ path }
          isLoginOverlayOpen={ isLoginOverlayOpen }
          onMobileMenuOpen={ this.handleMobileMenuOpen }
        />
        <WebsocketClient dispatch={ dispatch } />
        <ScrollToTopButton />
        <Footer />
      </div>
    )
  }

  bindFocusElementRef = ref => {
    this.focusElement = ref
  }

  handleMobileMenuOpen = () => {
    this.props.dispatch(toggleOverlay(false))
  }

  handleOverlayToggle = (status) => {
    this.props.dispatch(toggleOverlay(status || !this.props.isLoginOverlayOpen))
  }
}


export default withCookies(withRouter(App))
