import { createSelector } from 'reselect'
import Immutable from 'immutable'
import { itemIdsToItems } from '../components/item/itemsTransformers'

const selectMostRead = state => state.items.mostRead
const selectTimestamp = state => state.time.get('timestamp')

/**
 * @return {{items: Immutable.List<ItemRecord>, loading: boolean, timestamp: number}}
 */
export const selectNotFoundViewData = createSelector(
  [selectMostRead, selectTimestamp, state => state],
  (mostRead, timestamp, state) => {
    const itemIds = mostRead.getIn(['groups', 0, 'itemIds']) ?? Immutable.Set()
    const items = itemIdsToItems(state, itemIds.take(6).toList())
    return {
      items,
      loading: mostRead.get('loading'),
      timestamp,
    }
  }
)
