import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { Link } from 'react-router'
import {
  changeActiveList,
  fetchTagItems,
  fetchTagItemsBatch,
  fetchTagItemsNext,
  unqueueTagItems,
  LIST_NAME_NONE,
  LIST_TYPE_TAG,
} from '../../components/item/itemsActions'
import AbstractListView, { connectGenericProps } from './AbstractListView'
import TopNotification from '../../user/TopNotification'
import TagItemsEmpty from './TagItemsEmpty'
import { setStatus } from '../../status/statusActions'
import ListOptions from '../../components/ui/ListOptions'
import { fetchPopular } from '../../components/item/popularActions'
import { selectTagBySlug } from '../../selectors/tagsSelector'


@provideHooks({
  fetch: ({ dispatch, getState, params: { tag } }) => {

    dispatch(changeActiveList(LIST_TYPE_TAG, LIST_NAME_NONE))

    // Tag must be a valid slug. No need to fetch if it's not.
    if (tag && !tag.match(/^[a-z0-9-]+$/)) {
      dispatch(setStatus(404))
      return
    }

    return Promise.all([
      dispatch(fetchTagItems(tag)),
    ]).then(() => {
      const tagRecord = selectTagBySlug(getState(), tag)
      if (!tagRecord) {
        return dispatch(setStatus(404))
      }
      return dispatch(fetchPopular(900, { tagId: tagRecord.id }))
    })
  },
})
export class TagView extends AbstractListView {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    tag: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      h1: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      type: PropTypes.string,
      blacklisted: PropTypes.bool,
      whitelisted: PropTypes.bool,
      breaking: PropTypes.bool,
    }),
    tagSlug: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    notFound: PropTypes.bool.isRequired,
    activePage: PropTypes.shape({
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    loggedIn: PropTypes.bool.isRequired,
    listBlacklisted: PropTypes.bool.isRequired,
    listWhitelisted: PropTypes.bool.isRequired,
    showLanguageOptions: PropTypes.bool.isRequired,
  }

  onListDidUpdate(prevProps, props) {
    const { dispatch, tagSlug } = props

    if (prevProps.tagSlug !== tagSlug) {
      dispatch(changeActiveList(LIST_TYPE_TAG, tagSlug))
    }
  }

  setActiveList() {
    const { dispatch, tagSlug, notFound } = this.props

    if (notFound) {
      dispatch(changeActiveList(LIST_TYPE_TAG, LIST_NAME_NONE))
    } else {
      dispatch(changeActiveList(LIST_TYPE_TAG, tagSlug))
    }
  }

  refreshList() {
    const { dispatch, params: { tag } } = this.props
    dispatch(fetchTagItems(tag))
  }

  fetchNext(timestamp) {
    const { params: { tag } } = this.props

    return (dispatch) => dispatch(fetchTagItemsNext(tag, timestamp))
  }

  unqueueItems() {
    const { dispatch, params: { tag } } = this.props

    dispatch(unqueueTagItems(tag))
  }

  handleFetchBatchItems = (batch) => {
    const { dispatch, params: { tag } } = this.props

    dispatch(fetchTagItemsBatch(tag, batch))
  }

  getTitle() {
    const { tag } = this.props
    return tag ? `${tag.get('h1')} | Tuoreimmat uutiset | Ampparit.com` : ''
  }

  /**
   * For tags " | Uutiset" gets appended in ListTitle component so that we can hide it
   * on small screens. This is needed because [Päivitä lista] button is not floating
   * like in other views and takes some space away from the header line.
   */
  getH1() {
    const { tag } = this.props
    return tag ? tag.get('h1') : ''
  }

  renderListOptions() {
    const { tag, dispatch, listWhitelisted, listBlacklisted } = this.props
    return (
      <ListOptions
        tag={ tag }
        isCategory={ false }
        dispatch={ dispatch }
        whitelisted={ listWhitelisted }
        blacklisted={ listBlacklisted }
      />
    )
  }

  getMetaDescription() {
    const { tag } = this.props

    if (tag && tag.get('desc')) {
      return tag.get('desc')
    }
    if (tag) {
      return `Tuoreimmat uutiset aiheesta ${tag.get('h1')}. ${super.getMetaDescription()}`
    }

    return super.getMetaDescription()
  }

  renderBlacklistedNotification() {
    const { loggedIn } = this.props

    return (
      <TopNotification>
        { loggedIn ?
          <h2>
            Olet piilottanut tämän aihetunnisteen. Ota aihetunniste käyttöön <Link className='text-link' to='/asetukset/aihetunnisteet'>profiilissasi.</Link>
          </h2>
          :
          <h2>
            Olet piilottanut tämän aihetunnisteen. Ota aihetunniste käyttöön Pinnalla-boksista tai uutislistalta.
          </h2>
        }
      </TopNotification>
    )
  }

  renderEmpty() {
    const { tag } = this.props

    return <TagItemsEmpty tagSlug={ tag.get('slug') } tagName={ tag.get('name') } />
  }

  requireRefresh() {
    // This is temporary override of Items.jsx requireRefresh() for tag list.
    // Items are not receiving tags via websocket, thus tag list queue is not populated.
    // Do full list refresh always via Queue button.
    return true
  }
}

export default connect((state, ownProps) => {
  const tagSlug = ownProps.params.tag
  const itemsForTag = state.items.tags.get(tagSlug)

  let notFound = false
  let tag
  let tagBlacklisted = false
  let tagWhiteListed = false
  let activePage = null

  if (!state.tags.loading) {
    tag = selectTagBySlug(state, tagSlug)

    if (tag) {
      activePage = {
        title: '#' + tag.name,
        to: `/t/${tag.slug}`,
      }

      if (state.user.get('tags').get('blacklisted').contains(tag.id)) {
        tagBlacklisted = true
      }
      if (state.user.get('tags').get('whitelisted').contains(tag.id)) {
        tagWhiteListed = true
      }
    } else {
      notFound = true
    }
  }

  return Object.assign(connectGenericProps(state, itemsForTag), {
    notFound,
    activePage,
    tag,
    tagSlug,
    listBlacklisted: tagBlacklisted,
    listWhitelisted: tagWhiteListed,
    showLanguageOptions: false,
  })
})(TagView)
