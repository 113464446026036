import Immutable from 'immutable'
import { FETCH_CATEGORIES_SUCCESS } from '../item/categoryActions'
import { SET_SHOW_BLACKLISTED_PAGES } from './menuActions'
import { LOCATION_CHANGE } from 'react-router-redux'
import { CATEGORY_ID_URHEILU } from '../../lib/constants'


const initialState = Immutable.Map({
  tree: Immutable.List(),
  openBranches: Immutable.Set(),
})


export const MenuRecord = Immutable.Record({
  to: '',               // All must set the link path
  categoryId: null,     // Only actual categories set this
  menuTitle: null,      // Set to show in menus
  topNaviTitle: null,   // Set to show in top navi
  topNaviActive: false,
  pages: null,          // Immutable.List if has any subpages
  showBlacklistedPages: false,
}, 'menuRecord')

const aggregateItems = Immutable.List([
  new MenuRecord({ to: '/',               menuTitle: 'Etusivu',        topNaviTitle: 'Uutiset' }),
  new MenuRecord({ to: '/omat',           menuTitle: 'Omat aiheet'     }),
  new MenuRecord({ to: '/uusimmat',       menuTitle: 'Uusimmat'        }),
  new MenuRecord({ to: '/luetuimmat',     menuTitle: 'Luetuimmat'      }),
  new MenuRecord({ to: '/aanestetyimmat', menuTitle: 'Äänestetyimmät ' }),
  new MenuRecord({ to: '/maksulliset',    menuTitle: 'Maksulliset'     }), // Selectors will filter this if user has no subscriptions
])
const topNaviItems = Immutable.List([
  new MenuRecord({ to: '/tv',  topNaviTitle: 'TV' }),
  new MenuRecord({ to: '/saa', topNaviTitle: 'Sää' }),
])




export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS: {
      const categoryItems = createTree(action.response.data, state.get('openBranches'))
      return state.set('tree', aggregateItems.concat(categoryItems, topNaviItems))
    }

    case SET_SHOW_BLACKLISTED_PAGES: {
      const { show, path } = action
      const treeIndex = state.get('tree').findIndex(category => category.to === path)
      const openBranches = show ? state.get('openBranches').add(path) : state.get('openBranches').delete(path)
      return state
        .set('openBranches', openBranches)
        .setIn(['tree', treeIndex, 'showBlacklistedPages'], show)
    }

    case LOCATION_CHANGE: {
      const path = normalizeCurrentPath(action.payload.pathname)
      const oldActiveIndex = state.get('tree').findIndex(category => category.topNaviActive)
      let newActiveIndex = state.get('tree').findIndex(category => category.topNaviTitle != null && category.to === path)
      if (newActiveIndex === -1) {
        const sportsIndex = state.get('tree').findIndex(category => category.categoryId === CATEGORY_ID_URHEILU)
        const isSportsSubpage = sportsIndex !== -1 && state.getIn(['tree', sportsIndex, 'pages']).some(category => category.to === path)
        newActiveIndex = isSportsSubpage ? sportsIndex : 0 // One section is always active and the default is Uutiset
      }
      if (oldActiveIndex !== newActiveIndex && oldActiveIndex !== -1) {
        state = state.setIn(['tree', oldActiveIndex, 'topNaviActive'], false)
      }
      return state.setIn(['tree', newActiveIndex, 'topNaviActive'], true)
    }

    default:
      return state
  }
}


function createTree(categories, openBranches) {
  // Add root categories to the tree
  const tree = categories.filter(category => category.treeLevel === 1).map(category => ({
    to: '/' + category.name,
    menuTitle: category.properName,
    categoryId: category.id,
    topNaviTitle: category.id === CATEGORY_ID_URHEILU ? category.properName : null,
    ordering: category.ordering,
    isBranchOpen: openBranches.has('/' + category.name),
  }))
  tree.sort((a, b) => a.ordering - b.ordering)

  // Add 2nd level categories as subpages to the 1st level
  tree.forEach(topCategory => {
    const subPages = categories.filter(category => category.parentId === topCategory.categoryId).map(category => ({
      to: '/' + category.name,
      menuTitle: category.properName,
      categoryId: category.id,
      ordering: category.ordering,
    }))
    subPages.sort((a, b) => a.ordering - b.ordering)
    topCategory.pages = subPages
  })

  // Inject tulospalvelu to sport
  const sportsCategory = tree.find(topCategory => topCategory.categoryId === CATEGORY_ID_URHEILU)
  if (sportsCategory) {
    sportsCategory.pages.unshift({
      to: '/urheilu/tulospalvelu',
      menuTitle: 'Tulospalvelu',
    })
  }

  // Convert objects to MenuRecords
  const records = tree.map(topCategory => {
    if (topCategory.pages) {
      topCategory.pages = Immutable.List(topCategory.pages.map(subPage => new MenuRecord(subPage)))
    }
    return new MenuRecord(topCategory)
  })

  return Immutable.List(records)
}


export function normalizeCurrentPath(path) {
  if (path.startsWith('/saa/')) {
    return '/saa'
  }
  return path
}
